import React, { useState, useEffect } from "react"
import { connect } from "react-redux"

import InputGroup from "../InputGroup/InputGroup"
import "./NonDodIcMilitaryOne.css"

const currentUrl = window.location.href;
const isNonDodIc = currentUrl.includes("/application/non-dod-ic");

const initialInputData = [
  {
    id: 0,
    userField: "military.department",
    className: "department",
    inputType: "select",
    placeHolder: "Department",
    title: "What is your Department of Service?",
    required: true,
    options: [
      { id: 0, value: "Central Intelligence Agency" },
      { id: 1, value: "Defense Intelligence Agency" },
      { id: 2, value: "Department of Energy" },
      { id: 3, value: "Department of Homeland Security" },
      { id: 4, value: "Department of State" },
      { id: 5, value: "Department of the Treasury" },
      { id: 6, value: "Drug Enforcement Administration" },
      { id: 7, value: "Federal Bureau of Investigation" },
      { id: 8, value: "National Geospatial-Intelligence Agency" },
      { id: 9, value: "National Reconnaissance Office" },
      { id: 10, value: "National Security Agency" }
    ]
  },
  {
    id: 1,
    userField: "military.gs_level",
    className: "gsLevel",
    inputType: "text",
    placeHolder: "GS Level",
    title: "What was your final General Schedule level (GS)?",
    required: true
  },
  {
    id: 2,
    userField: "military.employmentStatus",
    className: "employmentStatus",
    inputType: "select",
    placeHolder: "Employment Status",
    title: "What is your current employment status?",
    required: true,
    options: [
      { id: 0, value: "Employed" },
      { id: 1, value: "Unemployed" },
      { id: 2, value: "Retired" },
      { id: 3, value: "Seeking Employment" },
      { id: 4, value: "Other" }
    ]
  },
  {
    id: 3,
    userField: "military.didServe",
    className: "didServe",
    inputType: "select",
    placeHolder: "Did you serve in the Military?",
    title: "Did you serve in the Military?",
    required: true,
    options: [
      { id: 0, value: "No" },
      { id: 1, value: "Yes" }
    ]
  },
  // Conditional fields (only show when "Did you serve?" is Yes)
  {
    id: 4,
    userField: "military.nonDodIcBranch",
    className: "branch invisible",
    inputType: "select",
    placeHolder: "Branch",
    title: "What branch?",
    required: false,
    conditional: "military.didServe === 'Yes'",
    options: [
      { id: 0, value: "Air Force" },
      { id: 1, value: "Army" },
      { id: 5, value: "Coast Guard" },
      { id: 2, value: "Marines" },
      { id: 4, value: "National Guard" },
      { id: 3, value: "Navy" },
      { id: 6, value: "Space Force" }
    ]
  },
  {
    id: 5,
    userField: "military.nonDodIcstatus",
    className: "status invisible",
    inputType: "select",
    placeHolder: "Military Status",
    title: "Military Status",
    required: false,
    conditional: "military.didServe === 'Yes'",
    options: [
      { id: 0, value: "National Guard" },
      { id: 1, value: "Reserve" },
      { id: 2, value: "Active Duty" }
    ]
  },
  {
    id: 6,
    userField: "military.nonDodIcRank",
    className: "rank invisible",
    inputType: "select",
    placeHolder: "Rank at separation/retirement",
    title: "Rank at separation/retirement",
    required: false,
    conditional: "military.didServe === 'Yes'",
    options: [
      { id: 0, value: "E-1" },
      { id: 1, value: "E-2" },
      { id: 2, value: "E-3" },
      { id: 3, value: "E-4" },
      { id: 4, value: "E-5" },
      { id: 5, value: "E-6" },
      { id: 6, value: "E-7" },
      { id: 7, value: "E-8" },
      { id: 8, value: "E-9" },
      { id: 9, value: "O-1" },
      { id: 10, value: "O-2" },
      { id: 11, value: "O-3" },
      { id: 12, value: "O-4" },
      { id: 13, value: "O-5" },
      { id: 14, value: "O-6" },
      { id: 15, value: "O-7" },
      { id: 16, value: "O-8" },
      { id: 17, value: "O-9" },
      { id: 18, value: "O-10" },
      { id: 19, value: "W-1" },
      { id: 20, value: "W-2" },
      { id: 21, value: "W-3" },
      { id: 22, value: "W-4" },
      { id: 23, value: "W-5" }
    ]
  },
  {
    id: 7,
    userField: "military.militaryOccupation",
    className: "militaryOccupation invisible",
    inputType: "text",
    placeHolder: "Military Occupation",
    title: "Military Occupation",
    required: false,
    conditional: "military.didServe === 'Yes'"
  },
  {
    id: 8,
    userField: "military.nonDodIcYearsOfService",
    className: "yearsOfService",
    inputType: "numberInput",
    placeHolder: "Years of Service (Including years until separation date)",
    title: "Years of Service",
    required: true
  },
  {
    id: 9,
    userField: "military.nonDodIcRetirementDate",
    className: "sepDate",
    inputType: "date",
    placeHolder: "What is your expected retirement/separation date? (MM/DD/YYYY)",
    title: "What is your expected retirement/separation date?",
    required: true
  },
  {
    id: 10,
    userField: "military.lastEmployment",
    className: "employment",
    inputType: "text",
    placeHolder: "Last Employment Position - Title and Location (city, state)",
    title: "Last Employment Position - Title and Location (city, state)",
    required: true
  }
];


const branchesWithDropdown = ["Navy", "Army", "Marines", "Air Force"];

const NonDodIcMilitaryOne = (props) => {
  const nonDodIcApplication = props.nonDodIcApplication;
  const inputsByField = initialInputData.reduce(
    (inputs, currentInput) => ({
      ...inputs,
      [currentInput.userField]: {
        ...currentInput,
        value: Object.byString(nonDodIcApplication, currentInput.userField) || "",
      },
    }),
    {}
  );

  const [inputs, setInputs] = useState(inputsByField);

  const getSofUnitOptions = (branchOfService) => {
    if (!branchesWithDropdown.includes(branchOfService)) return [];

    switch (branchOfService) {
      case "Navy":
        return [
          { id: 0, value: "JSOC" },
          { id: 1, value: "MARSOC" },
          { id: 2, value: "NSW Group 1" },
          { id: 3, value: "NSW Group 2" },
          { id: 4, value: "NSW Group 3" },
          { id: 5, value: "NSWDG" },
          { id: 6, value: "NSW Group 4" },
          { id: 7, value: "NSW Group 10" },
          { id: 8, value: "NSWC" },
          { id: 9, value: "NECC Group 1" },
          { id: 10, value: "NECC Group 2" },
        ];
      case "Army":
        return [
          { id: 0, value: "JSOC" },
          { id: 1, value: "75th RR" },
          { id: 2, value: "160th SOAR" },
          { id: 3, value: "NEXUS" },
          { id: 4, value: "1SFG" },
          { id: 5, value: "3SFG" },
          { id: 6, value: "5SFG" },
          { id: 7, value: "7SFG" },
          { id: 8, value: "10SFG" },
          { id: 9, value: "CAG" },
          { id: 10, value: "EOD" },
        ];
      case "Marines":
        return [{ id: 0, value: "MARSOC" }];
      case "Air Force":
        return [
          { id: 0, value: "AFSOC" },
          { id: 1, value: "EOD" },
        ];
      default:
        return [];
    }
  };

  const onInputChange = (input, value) => {
    const newInputsData = {
      ...inputs,
      [input.userField]: {
        ...input,
        value,
      },
    };
  
    const didServeValue = newInputsData["military.didServe"].value;

    // If "Did you serve?" is "No", clear related fields
    if (didServeValue === "No") {
      newInputsData["military.nonDodIcBranch"] = { 
        ...newInputsData["military.nonDodIcBranch"], 
        value: "" 
      };
      newInputsData["military.nonDodIcstatus"] = { 
        ...newInputsData["military.nonDodIcstatus"], 
        value: "" 
      };
      newInputsData["military.nonDodIcRank"] = { 
        ...newInputsData["military.nonDodIcRank"], 
        value: "" 
      };
      newInputsData["military.militaryOccupation"] = { 
        ...newInputsData["military.militaryOccupation"], 
        value: "" 
      };
    }
  
    setInputs({
      ...newInputsData,
      "military.nonDodIcBranch": {
        ...newInputsData["military.nonDodIcBranch"],
        className: didServeValue === "Yes" ? "branch" : "branch invisible",
      },
      "military.status": {
        ...newInputsData["military.nonDodIcstatus"],
        className: didServeValue === "Yes" ? "status" : "status invisible",
      },
      "military.nonDodIcRank": {
        ...newInputsData["military.nonDodIcRank"],
        className: didServeValue === "Yes" ? "rank" : "rank invisible",
      },
      "military.militaryOccupation": {
        ...newInputsData["military.militaryOccupation"],
        className: didServeValue === "Yes" ? "militaryOccupation" : "militaryOccupation invisible",
      },
    });
    
  };
  

  useEffect(() => {
    /* Handle initial visibility of dynamic fields */
    const didServeValue = inputs["military.didServe"].value;
    const branchOfServiceValue = inputs["military.nonDodIcBranch"]?.value || "";

    setInputs({
      ...inputs,
      "military.nonDodIcBranch": {
        ...inputs["military.nonDodIcBranch"],
        className: didServeValue === "Yes" ? "branch" : "branch invisible",
      },
      "military.status": {
        ...inputs["military.nonDodIcstatus"],
        className: didServeValue === "Yes" ? "status" : "status invisible",
      },
      "military.nonDodIcRank": {
        ...inputs["military.nonDodIcRank"],
        className: didServeValue === "Yes" ? "rank" : "rank invisible",
      },
      "military.militaryOccupation": {
        ...inputs["military.militaryOccupation"],
        className: didServeValue === "Yes" ? "militaryOccupation" : "militaryOccupation invisible",
      },
      "military.sofUnit": {
        ...inputs["military.sofUnit"],
        className:
          didServeValue === "Yes" &&
          branchesWithDropdown.includes(branchOfServiceValue)
            ? "sofUnit"
            : "sofUnit invisible",
        options: getSofUnitOptions(branchOfServiceValue),
      },
    });
  }, []);

  return (
    <InputGroup
      {...props}
      containerClass="nonDodIcMilitaryContainer"
      inputData={Object.values(inputs)}
      onInputChange={onInputChange}
      renderComponent={({ inputComponent, inputData, checkSubmittability }) =>
        props.renderComponent({
          ...props,
          inputData,
          inputComponent,
          checkSubmittability,
        })
      }
    />
  );
};

const mapStateToProps = (state) => ({
  nonDodIcApplication: state.nonDodIcApplication.application,
});

export default connect(mapStateToProps)(NonDodIcMilitaryOne);
