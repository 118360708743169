import React, { useState, useEffect } from "react"
import { useSelector } from "react-redux"
import TextField from "@material-ui/core/TextField"
import InputGroup from "../InputGroup/InputGroup"
import colors from "../../Constants/colors"

import "./PersonalInfoInputs.css"

const mapSelectOptions = (option, index) => {
  if (typeof option === "string") {
    return {
      id: index,
      value: option
    }
  }

  return {
    id: index,
    ...option
  }
}

const inputs = [
  {
    id: 0,
    className: "nickName",
    inputType: "text",
    userField: "nickName",
    placeHolder: "Preferred Name/Nickname (Optional)",
    required: false,
    value: ""
  },
  {
    id: 1,
    className: "dateOfBirth",
    placeHolder: "Date Of Birth (MM/DD/YYYY)",
    userField: "dateOfBirth",
    inputType: "date",
    type: "date",
    title: "Date of Birth",
    required: true,
    value: ""
  },
  {
    id: 2,
    value: "",
    className: "phoneNumber",
    inputType: "text",
    userField: "phoneNumber",
    placeHolder: "Phone Number",
    title: "Phone Number",
    required: true
  },
  {
    id: 3,
    className: "gender",
    userField: "gender",
    inputType: "select",
    placeHolder: "Gender",
    title: "Gender",
    options: ["Female", "Male", "Other", "Prefer Not To Say"].map(
      mapSelectOptions
    ),
    required: true
  },
  {
    id: 4,
    className: "ethnicity",
    userField: "ethnicity",
    inputType: "select",
    placeHolder: "Ethnicity",
    title: "Ethnicity",
    options: [
      "Black",
      "Latino",
      "White / Caucasian",
      "Asian",
      "Pacific Islander",
      "Other",
      "American Indian",
      "Prefer Not To Say"
    ].map(mapSelectOptions),
    required: true
  },
  {
    id: 5,
    className: "maritalStatus",
    userField: "marital_status",
    inputType: "select",
    placeHolder: "Martial Status",
    title: "Marital Status",
    options: ["Married", "Not Married", "Prefer Not To Say"].map(
      mapSelectOptions
    ),
    required: true
  },
  {
    id: 6,
    className: "linkedInUrl",
    userField: "linkedin_url",
    inputType: "text",
    placeHolder: "LinkedIn URL (Please provide or write not available)",
    title: "LinkedInUrl",
    required: true
  },
  {
    id: 7,
    placeHolder: "hearAboutCommit",
    className: "howDidYouHear",
    userField: "hearAboutCommit",
    inputType: "select",
    required: true,
    options: [
      { value: "Friend/Colleague (Please provide name)", showOtherInput: true },
      { value: "Online", showOtherInput: false },
      { value: "Workday", showOtherInput: false },
      { value: "Social Media", showOtherInput: false },
      {
        value: "Installation/Military Program Referral (Please provide name)",
        showOtherInput: true
      },
      { value: "Unit Referral (Please provide name)", showOtherInput: true },
      { value: "Wounded Warriors Project", showOtherInput: false },
      {
        value: "Other Veteran Service Organization (Please provide name)",
        showOtherInput: true
      },
      { value: "Other (Please describe below)", showOtherInput: true }
    ].map(mapSelectOptions),
    title: "How did you hear about COMMIT?"
  }
]

const PersonalInfoInputs = (props) => {
  const { changeHandler, renderComponent } = props

    const application = useSelector((state) => {
            return state.application.application;
    });
  
  const initialInputDataWithValues = inputs.map((input) => ({
    ...input,
    value: application[input.userField] || ""
  }))

  const [otherValue, setOtherValue] = useState(application.hearAboutCommitOther)
  const [inputData, setInputData] = useState(initialInputDataWithValues)
  const [isOtherInputVisibe, setIsOrderInputVisible] = useState(false)

  const onOtherInputChange = ({ target: { value } }) => {
    setOtherValue(value)
    changeHandler(
      inputData.concat([
        {
          userField: "hearAboutCommitOther",
          value,
          className: "howDidYouHearDescription"
        }
      ])
    )
  }

  const validateField = (input, value) => {
    let error = false;
    let helperText = "";

    if (input.required && !value) {
      error = true;
      helperText = "This field is required";
    }

    return { error, helperText };
  };

  const onInputDataChange = (data) => {
    setInputData(data)
    changeHandler(data)
    let is_valid = true;
    if (Object.keys(data).length > 0) {
      data.forEach((d) => {
        const { error, helperText } = validateField(d, d.value);
        d.error = error;
        d.helperText = helperText;
        if (error && is_valid) {
          is_valid = false;
        }
      });
    } else {
      is_valid = false;
    }
  }

  const onInputChange = (input, value) => {
    if (!(input.userField === "hearAboutCommit")) return

    const { showOtherInput } = input.options.find(
      (option) => option.value === value
    )

    setIsOrderInputVisible(showOtherInput)
  }

  useEffect(() => {
    const hearAboutCommitInput = initialInputDataWithValues.find(
      (input) => input.userField === "hearAboutCommit"
    )

    const hearAboutOption = hearAboutCommitInput.options.find(
      (option) => option.value === hearAboutCommitInput.value
    )

    setIsOrderInputVisible(
      hearAboutOption ? hearAboutOption.showOtherInput : false
    )
  }, [])

  return (
    <InputGroup
      {...props}
      changeHandler={onInputDataChange}
      onInputChange={onInputChange}
      containerClass="personalInfoContainer"
      inputData={inputData}
      renderComponent={({ inputComponent, inputData, checkSubmittability }) =>
        renderComponent({
          ...props,
          inputData: inputData.concat([
            {
              userField: "hearAboutCommitOther",
              className: "howDidYouHearDescription",
              value: isOtherInputVisibe ? otherValue : ""
            }
          ]),
          inputComponent: (
            <>
              {inputComponent}
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center"
                }}
              >
                {isOtherInputVisibe && (
                  <div
                    className="howDidYouHearDescription"
                    style={{ width: "100%" }}
                  >
                    <TextField
                      value={otherValue}
                      label="How did you hear about COMMIT?"
                      type="text"
                      style={{
                        width: "100%",
                        color: colors.midnight
                      }}
                      id="standard-dense"
                      margin="dense"
                      onChange={onOtherInputChange}
                    />
                  </div>
                )}
              </div>
            </>
          ),
          checkSubmittability
        })
      }
    />
  )
}

export default PersonalInfoInputs
